<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <!-- HERO -->
      <page-header/>

      <section class="uk-section uk-section-small">
        <div class="uk-container">
          <div class="uk-height-medium uk-cover-container uk-border-rounded">
            <img src="https://picsum.photos/1300/500/?random" alt="Alt img" data-uk-cover>
            <div class="uk-overlay uk-overlay-primary uk-position-cover
              uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
              <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small" class="uk-width-2-3">
                <h2 class="uk-margin-top uk-margin-small-bottom uk-margin-remove-adjacent">{{$t("organization.market.AI")}}</h2>
                <form @submit.prevent="search">
                  <div class="uk-grid-small uk-flex-center uk-grid" uk-grid>
                    <div class="uk-width-expand uk-first-column">
                      <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon uk-icon" uk-icon="search"></span>
                        <input
                          class="uk-input"
                          type="text"
                          v-model="searchval"
                          placeholder="clinical technique, clinical expression, diagnosis name, etc."
                          required>
                      </div>
                    </div>
                    <div>
                      <table class="uk-table">
                        <tbody>
                          <tr>
                            <td class="uk-padding-remove">
                              <button type="button" class="uk-button uk-button-primary">
                                {{$t("organization.market.search")}}</button>
                            </td>
                            <td class="uk-padding-remove">
                              <button

                                uk-toggle="target: #modaldetailsearch"
                                class="uk-button uk-button-default uk-margin-small-left"
                              >{{$t("organization.market.rSearch")}}</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>

                <p></p>
              </div>
            </div>
          </div>
        </div>

        <div id="modaldetailsearch" uk-modal>
          <div class="uk-modal-dialog uk-modal-body">
            <h2 class="uk-modal-title">Refine search</h2>
            <div>
              <form class="uk-form-stacked">
                <fieldset class="uk-fieldset">
                  <div class="uk-margin" uk-grid>
                    <label class="uk-form-label" for="form-stacked-text">Image search</label>
                    <div class="uk-form-controls" uk-form-custom>
                      <input type="file">
                      <button type="button" class="uk-button uk-button-default"  tabindex="-1">Select</button>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <div class="uk-form-controls">
                      <p class="uk-margin-remove">
                        When entering multiple words
                        <span class="uk-text-danger">comma (,)</span>Enter them separately.
                      </p>
                      <textarea
                        class="uk-input uk-height-small"
                        disabled
                        id="form-stacked-text"
                        type="text"
                        placeholder="Key words Ex:(응급의료, 총론)"
                      ></textarea>
                    </div>
                    <hr>
                  </div>

                  <div class="uk-grid-match uk-grid-small uk-margin uk-child-width-1-1" uk-grid>
                    <div class="uk-width-1-5@m">
                      <label>
                        <input class="uk-checkbox" type="checkbox"> Time
                      </label>
                    </div>
                    <div class="uk-width-4-5@m">
                      <p class="uk-margin-remove uk-margin-small-bottom"> + If the period is not checked separately, the entire period is registered. (Last 1 month / 6 months / 1 year / 3 years / total)
                      </p>
                      <br>
                      <input class="uk-range" type="range" value="2" min="0" max="10" step="0.1">
                      <br>
                      <p class="uk-text-right uk-width-1-1 uk-margin-remove">Last 6 months</p>
                    </div>
                    <hr>
                  </div>

                  <div class="uk-grid-match uk-grid-small uk-margin uk-child-width-1-1" uk-grid>
                    <div class="uk-width-1-5@m">
                      <label>
                        <input class="uk-checkbox" type="checkbox">Point segments
                      </label>
                    </div>
                    <div class="uk-width-4-5@m">
                      <div uk-grid class="uk-grid-small">
                        <div class="uk-width-1-2@s">
                          <input class="uk-input" type="text" placeholder="50">
                        </div>
                        <div class="uk-width-1-2@s">
                          <input class="uk-input" type="text" placeholder="25">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-margin">
                    <div class="uk-width-1-3@s">
                      <label>
                        <input class="uk-checkbox" type="checkbox" placeholder="25">{{$t("organization.market.free")}}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <p class="uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-modal-close" >{{$t("organization.market.cancel")}}</button>
              <button type="button" class="uk-button uk-button-primary" >{{$t("organization.market.rSearch")}}</button>
            </p>
          </div>
        </div>
      </section>

      <div class="uk-container">
        <section>

          <div class="border-blue uk-padding-small uk-margin" uk-margin>
            <div>
              <div class="uk-flex-middle uk-flex-left" uk-grid>
                <div>
                  <span class="uk-text-emphasis uk-margin-left">
                    {{$t("organization.market.recom")}}</span>
                </div>
                <div>
                  <!-- <span
                    v-if="keywords!=null"
                    class="uk-text-small uk-text-bold"
                  >:{{keywords.length}}</span> -->
                  <div class="uk-margin-remove">
                    <div uk-form-custom="target: > * > span:first-child">
                      <select v-model="datatype" @change="onChangeDatatype()">
                        <option :value="1">{{$t("organization.market.img")}}</option>
                        <option :value="2">{{$t("organization.market.video")}}</option>
                        <option :value="3">{{$t("organization.market.audio")}}</option>
                        <option :value="4">{{$t("organization.market.stack")}}</option>
                      </select>
                      <button type="button" class="uk-button uk-button-default"
                       tabindex="-1">

                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div uk-filter="target: .js-filter" uk-margin>
                <div class="uk-flex-middle uk-flex-left" uk-grid>
                  <div class="uk-width-expand uk-margin">
                    <div class="uk-position-relative" uk-slider>
                      <ul
                        class="uk-margin-right uk-text-center uk-slider-items uk-child-width-auto uk-grid-small"
                      >
                        <li v-for="(item, keyword) in keywords" :key="keyword">
                          <span
                            @click="clickKeyword(item)"
                            class="uk-text-emphasis uk-link"
                          >{{item.keyword}}</span>
                        </li>
                      </ul>
                      <a
                        class="border-blue uk-text-emphasis uk-background-muted uk-position-left uk-padding-remove"
                        href="#"
                        uk-slider-item="previous"
                        uk-icon="icon:chevron-left;ratio: 1.2;"
                      ></a>
                      <a
                        class="border-blue uk-text-emphasis uk-background-muted uk-position-right"
                        href="#"
                        uk-slider-item="next"
                        uk-icon="icon:chevron-right;ratio: 1.2;"
                      ></a>
                    </div>
                  </div>
                  <!-- <div>
                      <a class="uk-text-emphasis" href="#" uk-icon="icon: grid;"></a>
                  </div>-->
                </div>

                <ul class="js-filter uk-child-width-1-1" uk-grid>
                  <li data-color="white">
                    <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider>
                      <ul
                        class="uk-slider-items uk-child-width-1-1 uk-child-width-1-4@s uk-grid-small"
                        uk-grid
                      >
                        <li v-for="(item, filter) in filtereddatas" :key="filter">
                          <div class="border-blue">
                            <div>
                              <router-link
                                :title="item.title"
                                tag="a"
                                :to="{
                                name: 'answer',
                                params: {
                                  answerId: item.id
                                }
                              }"
                              >
                                <vue-plyr
                                  data-width="500"
                                  data-height="300"
                                  class="uk-height-small"
                                  v-if="item.datatype == 2"
                                >
                                  <video poster :src="item.imageurl">
                                    <source :src="item.imageurl" type="video/mp4" size="720">
                                    <source :src="item.imageurl" type="video/mp4" size="1080">
                                    <track
                                      kind="captions"
                                      label="English"
                                      srclang="kr"

                                      default
                                    >
                                  </video>
                                </vue-plyr>
                                <vue-plyr
                                  data-width="500"
                                  data-height="100"
                                 v-else-if="item.datatype == 3"
                                >
                                  <audio>
                                    <source :src="item.imageurl" type="audio/mp3">
                                    <source :src="item.imageurl" type="audio/ogg">
                                  </audio>
                                </vue-plyr>
                                <img
                                  class="uk-height-small"
                                  v-else
                                  data-width="500"
                                  style="width:100%"
                                  :data-src="item.imageurl"
                                  data-height="300"
                                  data-uk-img
                                  alt
                                >
                              </router-link>
                            </div>
                            <div class="uk-padding-small uk-text-emphasis uk-text-small" uk-margin>
                              <div class="uk-child-width-expand@s uk-text-bold" uk-grid uk-margin>
                                <div>
                                  <span class="uk-text-emphasis">{{classes[item.datatype-1].class}}</span>
                                </div>
                                <div class="uk-text-right@s uk-text-success">
<!--                                  <span>{{item.price | currency}}-->
                                  <span>{{item.price}}
                                    {{$t("organization.market.point")}}</span>
                                </div>
                              </div>
                              <div>
                                <span>
                                  <router-link
                                    :title="item.title"
                                    tag="a"
                                    :to="{
                                name: 'answer',
                                params: {
                                  answerId: item.id
                                }
                              }"
                                  >{{item.title}}</router-link>
                                </span>
                                <br>
                                <span>
                                  <router-link
                                    class
                                    :title="item.title"
                                    tag="a"
                                    :to="{
                                      name: 'answer',
                                      params: {
                                        answerId: item.id
                                      }
                                    }"
                                  >{{ item.description | shortDescription}}</router-link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <a
                        class="border-blue uk-background-muted uk-text-emphasis uk-position-center-left"
                        href="#"
                        uk-slidenav-previous
                        uk-slider-item="previous"
                      ></a>
                      <a
                        class="border-blue uk-background-muted uk-text-emphasis uk-position-center-right"
                        href="#"
                        uk-slidenav-next
                        uk-slider-item="next"
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section v-if="maincates!=null && maincates.length > 0">
          <div uk-slider="finite: true">
            <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>
              <h2 class="uk-width-expand uk-text-center uk-text-left@s">{{$t("organization.market.fPic")}}</h2>
              <h5>
                <router-link
                  title="market"
                  tag="a"
                  :to="{name: 'datamarket',query: {
                mtype0: '1'
              }}"
                >View More</router-link>
              </h5>
              <div class="uk-visible@s">
                <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>
                <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>
              </div>
            </div>
            <div>
              <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                <div class="uk-position-relative">
                  <div class="uk-slider-container">
                    <div
                      class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid"
                    >
                      <article
                        v-for="(item, cates) in maincates"
                        :key="cates"
                        class="tm-product-card"
                      >
                        <div class="tm-product-card-media">
                          <div class="tm-ratio tm-ratio-4-3">
                            <router-link
                              class="tm-media-box"
                              :title="item.name"
                              tag="a"
                              :to="{ name: 'category',params: { slug: slugify(item.name),
                                        catid: item.id},query:{mtype0: '1'}}"
                            >
                              <figure
                                class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel"
                              >
                                <p
                                  class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"
                                  style="background: rgba(0,0,0,0.67); width: 100%"
                                >{{item.name}}</p>
                                <img :src="item.image" :alt="item.name">
                              </figure>
                            </router-link>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>
            </div>
          </div>
        </section>

        <br>
        <section v-if="maincates!=null && maincates.length > 0">
          <div uk-slider="finite: true">
            <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>
              <h2 class="uk-width-expand uk-text-center uk-text-left@s">{{$t("organization.market.rStack")}}</h2>
              <h5>
                <router-link
                  tag="a"
                  :to="{name: 'datamarket',query: {
                mtype0: '4'
              }}"
                >View More</router-link>
              </h5>
              <div class="uk-visible@s">
                <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>
                <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>
              </div>
            </div>
            <div>
              <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                <div class="uk-position-relative">
                  <div class="uk-slider-container">
                    <div
                      class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid"
                    >
                      <article
                        v-for="(item, index) in maincates"
                        :key="index"
                        class="tm-product-card"
                      >
                        <div class="tm-product-card-media">
                          <div class="tm-ratio tm-ratio-4-3">
                            <router-link
                              class="tm-media-box"
                              :title="item.name"
                              tag="a"
                              :to="{ name: 'category',params: { slug: slugify(item.name),
                                        catid: item.id},query:{mtype0: '4'}}"
                            >
                              <figure
                                class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel"
                              >
                                <p
                                  class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"
                                  style="background: rgba(0,0,0,0.67); width: 100%"
                                >{{item.name}}</p>
                                <img :src="item.image" :alt="item.name">
                              </figure>
                            </router-link>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>
            </div>
          </div>
        </section>
        <br>
        <section v-if="maincates!=null && maincates.length > 0">
          <div uk-slider="finite: true">
            <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>
              <h2 class="uk-width-expand uk-text-center uk-text-left@s">{{$t("organization.market.fVideo")}}</h2>
              <h5>
                <router-link
                  tag="a"
                  :to="{name: 'datamarket',query: {
                mtype0: '2'
              }}"
                >{{$t("organization.market.vMore")}}</router-link>
              </h5>
              <div class="uk-visible@s">
                <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>
                <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>
              </div>
            </div>
            <div>
              <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                <div class="uk-position-relative">
                  <div class="uk-slider-container">
                    <div
                      class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid"
                    >
                      <article
                        v-for="(item, index) in maincates"
                        :key="index"
                        class="tm-product-card"
                      >
                        <div class="tm-product-card-media">
                          <div class="tm-ratio tm-ratio-4-3">
                            <router-link
                              class="tm-media-box"
                              :title="item.name"
                              tag="a"
                              :to="{ name: 'category',params: { slug: slugify(item.name),
                                        catid: item.id},query:{mtype0: '2'}}" >
                              <figure
                                class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel"
                              >
                                <p
                                  class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"
                                  style="background: rgba(0,0,0,0.67); width: 100%"
                                >{{item.name}}</p>
                                <img :src="item.image" :alt="item.name">
                              </figure>
                            </router-link>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>
            </div>
          </div>
        </section>

        <div class="uk-position-relative uk-padding-small uk-margin border-blue">
          <div class="uk-margin-small-top uk-margin-small-bottom">
            <div class="uk-text-emphasis uk-text-center">
              <p class="uk-margin-remove">
                {{$t("organization.market.share")}}
              </p>
            </div>
          </div>
        </div>

        <section class="uk-section-small" v-if="faudios!=null && faudios.length>0">
          <div class="uk-grid-small uk-flex-middle uk-margin-bottom uk-grid uk-height-1-1">
            <h2 class="uk-width-expand uk-text-center uk-text-left@s uk-first-column">{{$t("organization.market.rAudio")}}</h2>
            <h5>
              <router-link
                tag="a"
                :to="{name: 'datamarket',query: {
                mtype0: '3'
              }}"
              >View More</router-link>
            </h5>
            <div class="uk-visible@s"></div>
          </div>
          <div class="uk-overflow-auto">
            <table
              class="uk-table uk-table-hover uk-table-striped uk-text-center uk-table-responsive border-table"
            >
              <thead>
                <tr>
                  <th class="uk-text-center uk-text-bold">Field</th>
                  <th class="uk-text-center uk-text-bold">Audio</th>
                  <th class="uk-text-center uk-text-bold">information</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, faudio) in faudios" :key="faudio">
                  <td>
                    <router-link
                      class="uk-text-emphasis"
                      :title="item.title"
                      tag="a"
                      :to="{
                      name: 'answer',
                      params: {
                        answerId: item.id
                      }
                    }"
                    >{{item.title}}</router-link>
                  </td>
                  <td>
                    <vue-plyr data-width="500" data-height="300" v-if="item.datatype == 3">
                      <audio>
                        <source :src="item.imageurl" type="audio/mp3">
                        <source :src="item.imageurl" type="audio/ogg">
                      </audio>
                    </vue-plyr>
                  </td>
                  <td>
                    <router-link
                      v-for="(keyitem, keyindex) in item.keywords"
                      :key="keyindex"
                      class="uk-button uk-button-default uk-button-small uk-margin-small-right uk-margin-small-bottom"
                      title="keyitem"
                      tag="a"
                      :to="{name: 'datamarket',
                      query: {
                mtype0: '3',
                keyword:keyitem
              }}"
                    >{{keyitem}}</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <page-footer/>

    </div>
  </layout>
</template>

<script scoped>
import Layout from '../../layouts/Layout'
import CategoryService from '@/services/CategoryService'
import AnswersService from '@/services/AnswersService'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

export default {
  name: 'Home',
  components: {
    Layout,
    PageHeader,
    PageFooter

  },
  data () {
    return {
      searchval: '',
      maincates: null,
      faudios: null,
      keywords: null,
      filtereddatas: null,
      datatype: 1,
      classes: [
        { class: 'image' },
        { class: 'video' },
        { class: 'audio' },
        { class: 'stack' }
      ]
    }
  },

  created () {},
  async mounted () {
    this.loadMainCates()
    this.loadFeaturedData()
    this.loadKeys()
  },
  methods: {
    onChangeDatatype () {
      this.loadKeys()
    },
    clickKeyword: function (item) {
      this.loadKeywordData(item.datatype, item.keyword)
    },
    loadKeywordData (datatype, keyword) {
      AnswersService.getAnswersByPage({
        mtype: datatype,
        keyword: keyword,
        size: 35
      }).then(res => {
        const { data } = res
        this.filtereddatas = data.content
        if (!this.filtereddatas.length) {
          return
        }
      })
    },
    async search () {
      this.$router.push({
        name: 'datamarket',
        query: { q: this.searchval }
      })
    },
    loadMainCates () {
      CategoryService.getMainCategories().then(res => {
        const { data } = res
        // console.log(data)
        this.maincates = data
      })
    },
    loadFeaturedData () {
      const datatype = 3
      AnswersService.getFeuturedDatas({ datatype: datatype }).then(res => {
        const { data } = res
        this.faudios = data
        if (!this.faudios.length) {
          return
        }
      })
    },
    loadKeys () {
      AnswersService.getKeys({ datatype: this.datatype }).then(res => {
        const { data } = res
        this.keywords = data
        if (!this.keywords.length) {
          return
        }
        this.loadKeywordData(
          this.keywords[0].datatype,
          this.keywords[0].keyword
        )
      })
    },
    slugify: function (value) {
      if (!value) return ''

      var slug = ''
      // Change to lower case
      var valueLower = value.toLowerCase()
      // Letter "e"
      slug = valueLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '_')

      return slug
    }
  },
  computed: {},
  filters: {
    shortDescription (value) {
      if (value && value.length > 100) {
        return value.substring(0, 100) + '...'
      } else {
        return value
      }
    }
  }
}
</script>

<style scoped>
table tr td:last-child{
  max-width: 250px;
}
</style>
