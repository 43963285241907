import { render, staticRenderFns } from "./OrganizMarket.vue?vue&type=template&id=464d796c&scoped=true&"
import script from "./OrganizMarket.vue?vue&type=script&scoped=true&lang=js&"
export * from "./OrganizMarket.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./OrganizMarket.vue?vue&type=style&index=0&id=464d796c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464d796c",
  null
  
)

export default component.exports