import Api from '@/services/Api'
const resource = '/categories'
export default {
  getMainCategories (params = {}) {
    return Api().get(`${resource}`, {
      params: params
    })
  },
  getSubCates (id) {
    return Api().get(`${resource}/${id}`)
  }
}
